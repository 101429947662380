.c-sidebar-brand-saval {
  a {
    display: block;
    padding: $spacer $spacer 0;
  }
}

.c-sidebar-nav-title {
  font-size: 1.25em;
}

.c-sidebar-nav {
  font-size: 1.1em;
}
