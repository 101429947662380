label.required:after,
legend.required:after {
  color: $danger-dark;
  content: ' *';
  display: inline;
}

.openingDays {
  .control-group {
    .controls:first-child {
      margin-left: 10px;
    }
    > .control-label {
      width: 50px;
    }
  }
}

.form-control::placeholder {
  color: $gray-400;
}

.openingDays {
  h4 {
    margin: ($spacer * 1.25) 0;
  }

  div.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    legend {
      width: 100px;
      flex-shrink: 0;

      + div {
        display: flex;
        flex-wrap: wrap;
        margin: (-($spacer / 2)) 0 (-($spacer / 2)) (-($spacer / 2));
      }
    }

    fieldset.form-group {
      margin: $spacer / 2;

      > div {
        display: flex;
        padding: $spacer / 2;
        background-color: $gray-100;
        border-radius: $border-radius;
        margin: 0;

        .form-group {
          margin-bottom: 0;

          label {
            margin: 0 ($spacer / 2);
            text-transform: lowercase;
          }

          &:first-child {
            label {
              display: none;
            }
          }
        }
      }
    }
  }
}

.opening-hours-button {
  display: flex;
  margin: -($spacer / 2);

  button {
    margin: $spacer / 2;
    border: none;
    background-color: transparent;
    font-size: 1.35em;
  }
}

.open-days {
  list-style: none;
  padding: 0;
  display: flex;
  margin: -($spacer / 2);
  margin-bottom: $spacer;

  li {
    padding: $spacer / 2;

    a {
      display: block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: $gray-100;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: $gray-200;
      }
    }

    &.is-active {
      a {
        background-color: $info-dark;
        color: $white;
      }
    }
  }
}

.site_openingHours_wrapper {
  display: flex;
  align-items: flex-start;
}

a.copy-times {
  color: $info-dark !important;
  font-size: 1.25em;
  margin: ($spacer * 0.75) $spacer;
  text-align: center;
  display: flex;
  align-items: center;

  .svg-inline--fa {
    font-size: 1.25em;
    margin-right: $spacer / 2;
  }
}

#site_openingHours {
  > .form-group {
    display: none;

    &.is-active {
      display: block;
    }
  }
}

#user_settings {
  display: flex;
}