<template>
  <div class="row mt-md-4">
    <div class="col-12" v-if="objectMeasurement.error === ''">
      <div class="row">
        <div class="col-12">
          <span class="text-value-lg">
              {{ title }}
          </span>
        </div>
        <div class="col-12 col-xl-6 mt-3" v-for="(chart, index) in chartData" :key="index">
          <div class="card bg-white"
            :class="{'clickable-card': !hasAmount[index] && chart.noDataLink}"
            @click="navigate(index, chart.noDataLink)">
            <div class="card-body d-flex flex-column">
              <span class="text-value-lg">
                {{ chart.title }}
              </span>
              <CChartDoughnut
                  style="height:200px;"
                  :labels="chart.labels"
                  :datasets="chart.dataSet"
                  :options="options"
                  v-if="hasAmount[index]"
              />
              <span v-else v-html="chart.noDataLabel"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-else>
      <div class="card bg-white">
        <div class="card-body d-flex flex-column">
          <span>
            {{ objectMeasurement.error }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {CChartDoughnut} from '@coreui/vue-chartjs'
import DataCheckMixin from "../DataCheckMixin";

export default {
  mixins: [DataCheckMixin],
  components: {
    CChartDoughnut
  },
  props: {
    noDataLabel: {
      type: String,
      required: true,
      default: ''
    },
    noDataLink: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    objectMeasurement: {
      type: Object,
      required: true,
      default: () => ({
        error: '',
        data: [],
      })
    }
  },
  data() {
    return {
      hasAmount: [],
      chartData: [],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          boxWidth: 400,
        },
        legend: {
          position: 'left',
          labels: {
            generateLabels: function (chart) {
              const labels = [];
              for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
                const amount = chart.data.labels[i].match(/(\d+)/g)
                const fillStyle = parseInt(amount[1], 10) <= 0 ? '#FFFFFF' : chart.data.datasets[0].backgroundColor[i]
                const text = chart.data.labels[i]
                if (chart.data.labels[i]) {
                  labels.push({ text, fillStyle});
                }
              }
              return labels
            }.bind(this)
          },
          onClick: function (event, legendItem) {
            if (this.getAmountFromLabel(legendItem.text) <= 0) {
              return;
            }
            const n = legendItem.text.split(':')[0] // ConditionMeasurement Number
            const obj = legendItem.text.trim().split('-')[0] // ConditionMeasurement Object
            const conditionMeasurement = `${n}%00`
            const title = obj.split(':')[1].trim()
            switch (title) {
              case 'Blusser':
                window.open(`/physical-object/?objectGroup=extinguisher&conditionMeasurement=${conditionMeasurement}`, '_self')
                break;
              case 'Haspel':
                window.open(`/physical-object/?objectGroup=reel&conditionMeasurement=${conditionMeasurement}`, '_self')
                break;
              case 'Noodverlichting':
                window.open(`/physical-object/?objectGroup=emergency%20lighting&conditionMeasurement=${conditionMeasurement}`, '_self')
                break;
              case 'Overig':
                window.open(`/physical-object/?objectGroup=other&conditionMeasurement=${conditionMeasurement}`, '_self')
                break;
              default:
                window.open(`/physical-object/?conditionMeasurement=${conditionMeasurement}`, '_self')
            }
          }.bind(this),
          onHover: function (event, legendItem) {
            if (this.getAmountFromLabel(legendItem.text) <= 0) {
              return;
            }
            event.target.style.cursor = 'pointer';
          }.bind(this),
          onLeave: function (event, legendItem) {
            if (this.getAmountFromLabel(legendItem.text) <= 0) {
              return;
            }
            event.target.style.cursor = 'default';
          }.bind(this),
        }
      }
    }
  },
  watch: {
    objectMeasurement: {
      deep: true,
      immediate: true,
      handler(objects) {
        this.clearData()

        if (!this.hasKeys(objects)) {
          return
        }

        objects.data.forEach((obj) => {
          const {physicalObjectCategories} = obj
          const colors = this.generateLabelColors(physicalObjectCategories)
          this.chartData.push({
            title: obj.title,
            noDataLabel: obj.noDataLabel,
            noDataLink: obj.noDataLink,
            dataSet: [
              {
                data: physicalObjectCategories.map(({ amount }) => amount),
                backgroundColor: colors.map((color) => color.color)
              }
            ],
            labels: colors.map(({ label, amount, conditionMeasurement }) => `${conditionMeasurement}: ${obj.title} - ${label} (${amount})`),
          })
        })

        for (let i = 0, j = this.chartData.length; i < j; i++) {
          for (let k = 0, l = this.chartData[i].dataSet.length; k < l; k++) {
            this.hasAmount.push(this.chartData[i].dataSet[k].data.some((amount) => amount > 0))
          }
        }
      }
    }
  },
  methods: {
    clearData() {
      this.chartData = []
      this.hasAmount = []
    },
    generateLabelColors(labels) {
      return labels.map(({label, amount, conditionMeasurement}) => {
        switch (conditionMeasurement) {
          case 0:
            return {
              label,
              amount,
              conditionMeasurement,
              color: '#8a93a2'
            }
          case 1:
            return {
              label,
              amount,
              conditionMeasurement,
              color: '#50af31'
            }
          case 2:
            return {
              label,
              amount,
              conditionMeasurement,
              color: '#c7d301'
            }
          case 3:
            return {
              label,
              amount,
              conditionMeasurement,
              color: '#ffed00'
            }
          case 4:
            return {
              label,
              amount,
              conditionMeasurement,
              color: '#fbb900'
            }
          case 5:
            return {
              label,
              amount,
              conditionMeasurement,
              color: '#ec6707'
            }
          case 6:
            return {
              label,
              amount,
              conditionMeasurement,
              color: '#e30613'
            }
          default:
            return {
              label,
              amount,
              conditionMeasurement,
              color: '#8a93a2'
            }
        }
      })
    },
    navigate(blockIndex, link) {
      if (this.hasAmount[blockIndex]) {
        return;
      }
      window.open(link);
    }
  }
}
</script>

<style>
.clickable-card {
  cursor: pointer;
}
</style>