.page-title-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .btn,
  .align-left {
    margin-left: auto;
  }
}
.page-title {
  font-family: "Unica One", sans-serif;
  text-transform: uppercase;
}

@media(max-width: 767px) {
  .page-title-wrapper {
    display: block;

    .btn,
    .align-left {
      display: block;
    }
  }
}
