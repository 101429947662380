@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Unica+One&display=swap');

////////////////////////////////////////////////////////////////
// CoreUI Override Variables
////////////////////////////////////////////////////////////////
$theme-colors: (
  "primary": #E71C23,
  "secondary": #EEEEEE,
  "tertiary": #3c4b64,
  "quaternary": #FED337,
  "accent": #7A7A7A,
  "error": #e55353,
);

////////////////////////////////////////////////////////////////
// Custom Variables
////////////////////////////////////////////////////////////////
$font_unica: 'Unica One', cursive;
$font_openSans: 'Open Sans', sans-serif;
$font_size-base: 0.75rem;
$grid-breakpoints: (
  xs:  0,
  sm:  576px,
  md:  768px,
  lg:  992px,
  xl:  1200px,
  xxl: 1600px
);

$form-check-input-margin-y: 0.1rem;
