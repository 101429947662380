.right-list {
  list-style: none;
  li {
    padding-left: 1em;
    text-indent: -1em;
    &:before {
      padding-right: 5px;
    }
  }
  li.active:before {
    content: "\01F5F8";
    color: $success;
  }
  li.inactive:before {
    content: "\2573";
    color: $danger;
  }
}

img {
  max-width: 100% !important;
}