@import "~bootstrap-vue/dist/bootstrap-vue.css";

.status-remark {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: -4px;

  span {
    border-radius: 12px;
    padding: 5px 12px;
    margin: 4px 4px 0 0;
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    line-height: 1.2;
  }

  // These classes are in dutch because we use the status from the site which comes from Saval directly
  .status-color-ok {
    background-color: #50af31;
    color: $white;
  }
  .status-color-aandacht {
    background-color: #ffed00;
  }
  .status-color-actie {
    background-color: #e30613;
    color: $white;
  }

  // Condition of objects. colors are in line with NEN2767
  .condition-unknown {
    background-color: $gray-600;
    color: $white;
  }

  .condition-excellent {
    background-color: #50af31;
    color: $white;
  }

  .condition-good {
    background-color: #c7d301;
  }

  .condition-reasonable {
    background-color: #ffed00;
  }

  .condition-mediocre {
    background-color: #fbb900;
  }

  .condition-poor {
    background-color: #ec6707;
    color: $white;
  }

  .condition-very-poor {
    background-color: #e30613;
    color: $white;
  }
}

.items-per-page {
  width: 50px;
}

.data-table-options {
  display: none;
  flex-wrap: wrap;
  margin-bottom: $spacer;

  &.active {
    display: flex;
  }
}

.table {
  th {
    white-space: nowrap;
  }

  &.b-table {
    > thead {
      > tr {
        .table-b-table-default {
          background-color: $gray-100;
        }
      }
    }
    > tbody {
      > tr {
        .table-b-table-default {
          background-color: $gray-100 !important;
        }

        &:nth-child(odd):not(.b-table-top-row) {
          background-color: rgba(0, 0, 21, 0.05) !important;

          &:hover {
            .table-b-table-default {
              background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)) !important;
            }
          }
        }

        .actions {
          text-align: center;
          margin: 0 (-($spacer / 2));

          a {
            display: inline-block;
            margin: 0 $spacer / 2;
          }
        }
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  .data-table-options {
    margin-bottom: $spacer / 2;

    > div {
      margin-bottom: $spacer / 2;
    }
  }
}

.modal {
  padding-left: 0;
  overflow: hidden !important;

  .modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .modal-header {
    align-items: center;
  }
}


@supports ((position: -webkit-sticky) or (position: sticky)) {
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky !important;
  }

  .b-table-top-row {
    position:sticky !important;
    left: 0;
    z-index: 5;
    top: 43px;
    background-color:#dfe1e4 !important;
  }
}