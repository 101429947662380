.order-quote-form {
  display: flex;
  flex-wrap: wrap;
  margin: -($spacer / 2);

  > .form-group {
    padding: $spacer / 2;
    margin: 0;

    &:first-child {
      flex-grow: 1;
      width: 100%;
    }
  }
}
