.error-reporting-search-page {
  .scanner {
    max-width: 100%;
    #barcodeScanner {
      display: none;
      canvas.drawingBuffer {
        display: none;
      }
    }
  }
}

.error-reporting-title {
  .error-reporting-barcode {
    font-weight: bold;
  }
}

.error-reporting-fill-in-report {
  .error-report-element-label {
    display: inline-block;
    width: 150px;
  }
  div.custom-switch {
    padding-left: initial;
    .error-report-options {
      display: none;
    }
  }
}

input.custom-control-input[type=checkbox]:checked ~ .error-report-options {
  display: block;
}
